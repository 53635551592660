<template>
    <v-card rounded="" color="grey lighten-5">
        <div class="pa-18px pa-md-24px text-center">
            <v-avatar size="60" color="white" class="mb-8px mb-md-12px">
                <icon-funds />
            </v-avatar>
            <div class="txt txt--sm txt--dark font-weight-medium mb-4px">{{ user?.name }} 님의 현재 보유 보너스</div>
            <div class="font-size-18 font-size-lg-28 font-weight-bold">{{ user?.bonus?.format?.() || 0 }}원</div>

            <div class="pt-8px">
                <bonus-withdraw-dialog @search="getUser">
                    <template #activator="{attrs,on}">
                        <v-btn v-bind="attrs" v-on="on" color="primary">보너스 출금하기</v-btn>
                    </template>
                </bonus-withdraw-dialog>
            </div>
        </div>
    </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { attrs_input } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import TitWrapSmall from "@/components/client/dumb/tit-wrap-small.vue";
import BonusWithdrawDialog from "./withdraw-dialog/bonus-withdraw-dialog.vue";
import IconFunds from "@/components/client/icons/icon-funds.vue";

export default {
    components: {
        UDialog,
        TitWrapSmall,
        BonusWithdrawDialog,
        IconFunds,
    },
    data: () => ({
        attrs_input,
    }),
    computed: {
        ...mapState(["user"]),
    },
    methods: {
        ...mapActions(["getUser"]),
    },
};
</script>

<style></style>
