<template>
    <client-page>
        <mypage-layout titleSpacingLg title="직급 포인트 관리" aside="promoter">
            <tit-wrap-line noDivider title="보너스 출금 계좌번호 정보" />

            <!-- S: 현재 등급 -->
            <section-current-level class="mb-15" />
            <!-- E: 현재 등급 -->

            <!-- S: 월별 보너스 내역 -->
            <scores-monthly class="my-15" />
            <!-- E: 월별 보너스 내역 -->
        </mypage-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import ScoresMonthly from "@/components/client/promoter/scores/scores-monthly.vue";
import SectionCurrentLevel from "@/components/client/promoter/purchases/section-current-level.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        ScoresMonthly,
        SectionCurrentLevel,
    },
};
</script>
