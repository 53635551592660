<template>
    <client-page>
        <mypage-layout titleSpacingLg title="계좌정보" aside="promoter">
            <tit-wrap-line noDivider title="보너스 출금 계좌번호 정보" />
            <vertical-form-table mode="view" v-model="user" v-bind="{ items }" />

            <!-- 버튼영역 -->
            <div class="btn-wrap btn-wrap--lg">
                <v-row justify="center" class="row--sm">
                    <v-col cols="6" sm="auto">
                        <bank-verification-form>
                            <template #activator="{ attrs, on }">
                                <v-btn v-bind="attrs" v-on="on" color="primary" class="v-size--xx-large w-100 min-w-sm-200px">계좌번호 변경</v-btn>
                            </template>
                        </bank-verification-form>
                    </v-col>
                </v-row>
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import { mapActions, mapState } from "vuex";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import BankVerificationForm from "@/components/client/promoter/bank/verification-form/bank-verification-form.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        TitWrapLine,
        VerticalFormTable,
        BankVerificationForm,
    },
    computed: {
        ...mapState(["user"]),
        items() {
            return [{ term: "은행계좌", key: "bank", type: "bank" }];
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            this.getUser();
        },
    },
};
</script>
