var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "titleSpacingLg": "",
      "title": "계좌정보",
      "aside": "promoter"
    }
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "보너스 출금 계좌번호 정보"
    }
  }), _c('vertical-form-table', _vm._b({
    attrs: {
      "mode": "view"
    },
    model: {
      value: _vm.user,
      callback: function ($$v) {
        _vm.user = $$v;
      },
      expression: "user"
    }
  }, 'vertical-form-table', {
    items: _vm.items
  }, false)), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('bank-verification-form', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-large w-100 min-w-sm-200px",
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', attrs, false), on), [_vm._v("계좌번호 변경")])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }