<template>
    <v-card color="white" rounded="md" class="fill-height">
        <div class="pa-12px pa-md-16px">
            <div class="txt txt--sm font-weight-medium grey--text text--darken-4">보너스 출금 계좌번호</div>
            <v-divider class="my-8px" />
            <div v-for="({ term, data }, index) in items" :key="term" :class="{ 'mt-8px' : index != 0 }">
                <v-row no-gutters justify="space-between" class="txt txt--xs">
                    <v-col cols="auto">
                        {{ term }}
                    </v-col>
                    <v-col cols="auto">
                        <span class="grey--text text--darken-4">{{ data || "-" }}</span>
                    </v-col>
                </v-row>
            </div>
        </div>
    </v-card>
</template>

<script>
import { initBank } from "@/assets/variables";
import banks from "@/plugins/shop-default-banks.json";

export default {
    props: {
        bank: { type: Object, default: initBank },
    },
    computed: {
        items() {
            return [
                { term: "은행명", data: banks.find(({ code }) => code == this.bank?.code)?.name },
                { term: "계좌번호", data: this.bank?.accountNumber },
                { term: "예금주", data: this.bank?.accountHolder },
            ];
        },
    },
};
</script>

<style></style>
