var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "titleSpacingLg": "",
      "title": "직급 포인트 관리",
      "aside": "promoter"
    }
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "보너스 출금 계좌번호 정보"
    }
  }), _c('section-current-level', {
    staticClass: "mb-15"
  }), _c('scores-monthly', {
    staticClass: "my-15"
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }