<template>
    <client-page>
        <mypage-layout title="나의 추천회원" aside="promoter">
            <div class="tabs-wrap">
                <promotees-type-tabs />
            </div>

            <div class="pb-20px">
                <v-row align="center" class="row--sm">
                    <v-spacer />
                    <v-col cols="12" sm="auto">
                        <table-search v-bind="{ loading }" />
                    </v-col>
                </v-row>
            </div>

            <v-data-table v-bind="{ headers, items }" hide-default-footer disable-filtering disable-pagination disable-sort dense mobile-breakpoint="0" class="v-data-table--default v-data-table--border v-data-table--min-w-894px" no-data-text="추천회원 내역이 없습니다">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
            </v-data-table>

            <div class="pagination-wrap">
                <v-pagination :value="page" :length="pageCount" :total-visible="11" color="primary" class="mt-2" @input="(page) => $router.push({ query: { ...$route.query, page } })" />
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import TableSearch from "@/components/client/dumb/table-search.vue";
import PromoteesTypeTabs from "@/components/client/promoter/promotees/promotees-type-tabs.vue";

const headers = [
    { width: "auto", align: "center", text: "아이디", value: "username" },
    { width: "auto", align: "center", text: "이름", value: "name" },
    { width: "auto", align: "center", text: "회원등급", value: "title" },
    { width: "auto", align: "center", text: "추천회원", value: "meta.promoteeCount.promotees" },
    { width: "auto", align: "center", text: "총매출", value: "orderPrice", formatter: (value) => `${value?.format?.() || value || 0}원` },
    { width: "auto", align: "center", text: "추천코드", value: "code_promoter.code" },
    { width: "auto", align: "center", text: "가입일", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: "auto", align: "center", text: "정회원 전환일", value: "approvedAt", formatter: (value) => value?.toDate?.() || value || "-" },
].map((item) => ({ ...item, formatter: item?.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        ClientPage,
        TableSearch,
        MypageLayout,
        PromoteesTypeTabs,
    },

    data: () => ({
        promotees: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,

        headers,
    }),
    computed: {
        items() {
            return [...this.promotees].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            if (!query.type) query.type = "promotees";

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, users } = await api.v1.promoter.promotees.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.promotees = users;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
