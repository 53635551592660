<template>
    <v-tab-item :value="tab">
        <!-- S: 월별 보너스 내역 -->
        <page-section class="page-section--sm py-0">
            <template #containerImmersive>
                <bonus-whole-monthly />
            </template>
        </page-section>
        <!-- E: 월별 보너스 내역 -->

        <!-- S: 보너스 상세내역 -->
        <page-section class="page-section--sm pb-0">
            <template #containerImmersive>
                <tit-wrap-line noDivider title="보너스 상세내역">
                    <table-search v-bind="{ loading }" />
                </tit-wrap-line>

                <v-data-table v-bind="{ headers, items }" hide-default-footer disable-filtering disable-pagination disable-sort dense mobile-breakpoint="0" class="v-data-table--default v-data-table--border v-data-table--min-w-894px" no-data-text="보너스 내역이 없습니다">
                    <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                </v-data-table>
                <div class="pagination-wrap">
                    <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
                </div>
            </template>
        </page-section>
        <!-- E: 보너스 상세내역 -->
    </v-tab-item>
</template>

<script>
import api from "@/api";
import { BONUS_TABS } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import TableSearch from "@/components/client/dumb/table-search.vue";
import BonusWholeMonthly from "./bonus-whole-monthly.vue";

const tab = BONUS_TABS.WHOLE.value;

const headers = [
    { width: "12.5%", align: "center", text: "보너스 번호", value: "_id", cellClass: "caption" },
    { width: "12.5%", align: "center", text: "지급날짜", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-", cellClass: "caption" },
    { width: "12.5%", align: "center", text: "명칭", value: "typeText", cellClass: "caption" },
    { width: "12%", align: "center", text: "충전/차감", value: "amount", cellClass: "caption", formatter: (value) => (0 < value ? "충전" : value < 0 ? "차감" : "-") },
    { width: "14%", align: "center", text: "내용", value: "remark", cellClass: "caption" },
    { width: "12%", align: "center", text: "퍼센트", value: "meta.bonusPercent", cellClass: "caption", formatter: (value) => (value ? `${value}%` : "-") },
    { width: "12.5%", align: "center", text: "보너스", value: "amount", formatter: (value) => `${value?.format?.() || value || 0}`, cellClass: "caption" },
].map((item) => ({ ...item, formatter: item?.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        PageSection,
        TitWrapLine,
        TableSearch,
        BonusWholeMonthly,
    },

    data: () => ({
        bonuses: [],

        tab,
        headers,

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,
    }),
    computed: {
        items() {
            return [...this.bonuses].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            if (!query.searchValue) delete query.searchKey;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, bonuses } = await api.v1.promoter.bonuses.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.bonuses = bonuses;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
