var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tit-wrap-line', {
    attrs: {
      "noDivider": ""
    }
  }, [_c('template', {
    slot: "titleContents"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-sm-center"
  }, [_c('span', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("현재 등급")]), _c('span', {
    staticClass: "txt txt--sm pl-sm-8px mt-4px mt-sm-0"
  }, [_vm._v("월 구매확정액이 3만원 이상 발생 시 장려 보너스와 직급 보너스를 받으실 수 있습니다")])])])], 2), _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--border",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "mobile-breakpoint": "768"
    },
    scopedSlots: _vm._u([{
      key: `item.salePrice`,
      fn: function (_ref) {
        var _value$format;
        var value = _ref.value;
        return [_c('div', {
          staticClass: "d-inline-flex align-center font-size-16 font-size-md-20 grey--text text--darken-4"
        }, [_c('span', {
          staticClass: "d-flex align-center primary--text"
        }, [_c('strong', [_vm._v(_vm._s((value === null || value === void 0 ? void 0 : (_value$format = value.format) === null || _value$format === void 0 ? void 0 : _value$format.call(value)) || 0))]), _c('small', {
          staticClass: "font-size-14 font-weight-regular pl-2px"
        }, [_vm._v("원")])]), _c('span', {
          staticClass: "font-size-14 px-8px"
        }, [_vm._v("/")]), _c('span', {
          staticClass: "d-flex align-center grey--text text--darken-4"
        }, [_c('strong', [_vm._v("30,000")]), _c('small', {
          staticClass: "font-size-14 font-weight-regular pl-2px"
        }, [_vm._v("원")])])])];
      }
    }], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }