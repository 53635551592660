<template>
    <client-page>
        <mypage-layout titleSpacingLg title="나의 구매확정 내역" aside="promoter">
            <!-- S: 현재 등급 -->
            <page-section class="page-section--sm py-0">
                <template #containerImmersive>
                    <section-current-level />
                </template>
            </page-section>
            <!-- E: 현재 등급 -->

            <!-- S: 월별 구매확정 금액 -->
            <page-section class="page-section--sm pb-0">
                <template #containerImmersive>
                    <section-promoter-purchases-monthly />
                </template>
            </page-section>
            <!-- E: 월별 구매확정 금액 -->

            <!-- S: 구매상세 내역 -->
            <page-section class="page-section--sm pb-0">
                <template #containerImmersive>
                    <tit-wrap-line noDivider title="구매상세 내역">
                        <table-search v-bind="{ loading }" />
                    </tit-wrap-line>

                    <v-data-table v-bind="{ headers, items }" hide-default-footer disable-filtering disable-pagination disable-sort dense mobile-breakpoint="0" class="v-data-table--default v-data-table--border v-data-table--min-w-894px" no-data-text="구매확정 내역이 없습니다">
                        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    </v-data-table>

                    <div class="pagination-wrap">
                        <v-pagination :value="page" :length="pageCount" :total-visible="11" @input="(page) => $router.push({ query: { ...$route.query, page } })"></v-pagination>
                    </div>
                </template>
            </page-section>
            <!-- E: 구매상세 내역 -->
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import TableSearch from "@/components/client/dumb/table-search.vue";
import PromoterAside from "@/components/client/promoter/aside/promoter-aside.vue";
import SectionCurrentLevel from "@/components/client/promoter/purchases/section-current-level.vue";
import SectionPromoterPurchasesMonthly from "@/components/client/promoter/purchases/section-promoter-purchases-monthly.vue";

const headers = [
    { width: "20%", align: "center", text: "상품주문번호", value: "purchaseNo" },
    { width: "14%", align: "center", text: "구매확정일", value: "completedAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: "42%", align: "center", text: "상품명", value: "product.name" },
    { width: "10%", align: "center", text: "수량", value: "amount" },
    { width: "14%", align: "center", text: "금액", value: "salePrice", formatter: (value) => `${value?.format?.() || value || 0}원` },
    { width: "14%", align: "center", text: "합계", value: "totalPrice", formatter: (value) => `${value?.format?.() || value || 0}원` },
].map((item) => ({ ...item, formatter: item?.formatter ?? ((value) => value ?? "-") }));

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        TitWrapLine,
        TableSearch,
        PromoterAside,
        SectionCurrentLevel,
        SectionPromoterPurchasesMonthly,
    },

    data: () => ({
        purchases: [],

        limit: 10,
        summary: { totalCount: 0 },

        loading: false,

        headers,
        attrs_input,
    }),
    computed: {
        items() {
            return [...this.purchases].map((item, index) => ({ index: this.summary.totalCount - (this.page - 1) * this.limit - index, ...item, totalPrice: (item.salePrice || 0) * (item.amount || 0) }));
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            if (query.searchValue) query.searchKey = "product.name";

            return { ...query, type: "promoter" };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            this.loading = true;
            try {
                let { skip, limit, params } = this;
                var { summary, purchases } = await api.v1.promoter.purchases.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.purchases = purchases;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
