<template>
    <settlement-monthly-table v-bind="{ title, makeItems }" />
</template>

<script>
import SettlementMonthlyTable from "../settlements/settlement-monthly-table.vue";

const title = "월별 포인트 내역";
const makeItems = (data) => [
    {
        rowName: "회원본인 직급포인트",
        reducer: (o, key) => ({ ...o, [key]: data[key]?.score?.remain__promoter }),
    },
    {
        rowName: "추천회원 직급포인트",
        reducer: (o, key) => ({ ...o, [key]: data[key]?.score?.remain__promotee }),
    },
    {
        rowName: "회원등급",
        reducer: (o, key) => ({ ...o, [key]: data[key]?.grade?.title }),
    },
    {
        rowName: "회원레벨",
        reducer: (o, key) => ({ ...o, [key]: data[key]?.grade?.value ? `Rb.${data[key]?.grade?.value}` : "-" }),
    },
];

export default {
    components: {
        SettlementMonthlyTable,
    },
    data: () => ({
        title,
        makeItems,
    }),
};
</script>
