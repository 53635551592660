<template>
    <div>
        <tit-wrap-line noDivider :title="title">
            <monthly-table-nav v-bind="{ value, loading }" @input="(year) => $emit('input', year)" />
        </tit-wrap-line>

        <v-data-table v-bind="{ headers, items, loading }" hide-default-footer disable-filtering disable-pagination disable-sort dense mobile-breakpoint="0" class="v-data-table--default v-data-table--border v-data-table--min-w-894px">
            <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
        </v-data-table>
    </div>
</template>

<script>
import dayjs from "dayjs";

import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import VerticalTable from "@/components/dumb/vertical-table.vue";
import MonthlyTableNav from "./monthly-table-nav.vue";

const headers = [
    { align: "center", text: "내용/월", value: "rowName", cellClass: "font-weight-medium grey--text text--darken-4" },
    { align: "center", text: "1월", value: "01" },
    { align: "center", text: "2월", value: "02" },
    { align: "center", text: "3월", value: "03" },
    { align: "center", text: "4월", value: "04" },
    { align: "center", text: "5월", value: "05" },
    { align: "center", text: "6월", value: "06" },
    { align: "center", text: "7월", value: "07" },
    { align: "center", text: "8월", value: "08" },
    { align: "center", text: "9월", value: "09" },
    { align: "center", text: "10월", value: "10" },
    { align: "center", text: "11월", value: "11" },
    { align: "center", text: "12월", value: "12" },
].map((item) => ({ ...item, formatter: item.formatter ?? ((value) => value?.format?.() ?? value ?? "-") }));

export default {
    components: {
        TitWrapLine,
        VerticalTable,
        MonthlyTableNav,
    },
    props: {
        value: { type: Number, default: dayjs().year() },
        items: { type: Array, default: () => [] },
        title: { type: String, default: "월별내역" },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        year: dayjs().year(),
        headers,
    }),
};
</script>

<style></style>
