var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('page-section', {
    staticClass: "page-section--sm py-0",
    scopedSlots: _vm._u([{
      key: "containerImmersive",
      fn: function () {
        return [_c('tit-wrap-line', {
          attrs: {
            "noDivider": "",
            "title": "보너스 출금내역"
          }
        }, [_c('table-search', _vm._b({}, 'table-search', {
          loading: _vm.loading
        }, false))], 1), _c('v-data-table', _vm._b({
          staticClass: "v-data-table--default v-data-table--border v-data-table--min-w-894px",
          attrs: {
            "hide-default-footer": "",
            "disable-filtering": "",
            "disable-pagination": "",
            "disable-sort": "",
            "dense": "",
            "mobile-breakpoint": "0",
            "no-data-text": "출금 내역이 없습니다"
          },
          scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
            return header.hasOwnProperty('formatter');
          }), function (header) {
            return {
              key: `item.${header.value}`,
              fn: function (_ref) {
                var value = _ref.value;
                return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
              }
            };
          })], null, true)
        }, 'v-data-table', {
          headers: _vm.headers,
          items: _vm.items
        }, false)), _c('div', {
          staticClass: "pagination-wrap"
        }, [_c('v-pagination', {
          attrs: {
            "value": _vm.page,
            "length": _vm.pageCount,
            "total-visible": 11
          },
          on: {
            "input": function (page) {
              return _vm.$router.push({
                query: Object.assign({}, _vm.$route.query, {
                  page
                })
              });
            }
          }
        })], 1)];
      },
      proxy: true
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }