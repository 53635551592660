var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "나의 추천회원",
      "aside": "promoter"
    }
  }, [_c('div', {
    staticClass: "tabs-wrap"
  }, [_c('promotees-type-tabs')], 1), _c('div', {
    staticClass: "pb-20px"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "align": "center"
    }
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_c('table-search', _vm._b({}, 'table-search', {
    loading: _vm.loading
  }, false))], 1)], 1)], 1), _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--border v-data-table--min-w-894px",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "dense": "",
      "mobile-breakpoint": "0",
      "no-data-text": "추천회원 내역이 없습니다"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    })], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items
  }, false)), _c('div', {
    staticClass: "pagination-wrap"
  }, [_c('v-pagination', {
    staticClass: "mt-2",
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11,
      "color": "primary"
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }