var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.BONUS_WITHDRAW_TABS.CONFIRM_DEPOSIT_DONE.value
    }
  }, [_c('div', {
    staticClass: "txt txt--xs txt--dark font-weight-medium text-center py-20px py-md-0"
  }, [_vm._v(" 출금이 완료되었습니다. ")]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--sm"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "v-size--xx-large",
    attrs: {
      "block": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('complete');
      }
    }
  }, [_vm._v("확인")])], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }