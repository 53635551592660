var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": _vm.title
    }
  }, [_c('monthly-table-nav', _vm._b({
    on: {
      "input": function (year) {
        return _vm.$emit('input', year);
      }
    }
  }, 'monthly-table-nav', {
    value: _vm.value,
    loading: _vm.loading
  }, false))], 1), _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--border v-data-table--min-w-894px",
    attrs: {
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-pagination": "",
      "disable-sort": "",
      "dense": "",
      "mobile-breakpoint": "0"
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    })], null, true)
  }, 'v-data-table', {
    headers: _vm.headers,
    items: _vm.items,
    loading: _vm.loading
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }