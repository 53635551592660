<template>
    <u-dialog v-model="shows" persistent title="보너스 출금하기" width="400">
        <template #activator="{attrs,on}">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <v-tabs-items v-model="tab">
            <tab-confirm-bonus-amount v-model="form" v-on="{ setTab }" />
            <tab-confirm-bank-account v-model="form" v-on="{ setTab }" />
            <tab-verify-user-identity v-model="form" v-on="{ setTab }" />
            <tab-confirm-deposit-done v-model="form" v-on="{ complete }" />
        </v-tabs-items>
    </u-dialog>
</template>

<script>
import { BONUS_WITHDRAW_TABS, initBonus__debit } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import TabConfirmBonusAmount from "./tab-confirm-bonus-amount.vue";
import TabConfirmBankAccount from "./tab-confirm-bank-account.vue";
import TabVerifyUserIdentity from "./tab-verify-user-identity.vue";
import TabConfirmDepositDone from "./tab-confirm-deposit-done.vue";

const initialTab = BONUS_WITHDRAW_TABS.CONFIRM_BONUS_AMOUNT.value;

export default {
    components: {
        UDialog,
        TabConfirmBonusAmount,
        TabConfirmBankAccount,
        TabVerifyUserIdentity,
        TabConfirmDepositDone,
    },
    data: () => ({
        form: initBonus__debit(),

        shows: false,
        tab: initialTab,
    }),
    mounted() {
        this.init();
    },
    watch: {
        shows() {
            this.init();
        },
    },
    methods: {
        init() {
            this.form = initBonus__debit();
            this.tab = initialTab;
        },
        setTab(tab) {
            this.tab = tab;
        },
        complete() {
            this.$emit("search");
            this.shows = false;
        },
    },
};
</script>

<style></style>
